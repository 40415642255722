import "scss/app.scss";
import { Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import AppContextProvider from "utils/context/app";

const RequireAuth = lazy(() => import("utils/requireAuth"));
const RequireCompany = lazy(() => import("utils/requireCompany"));
const RequireActiveCompany = lazy(() => import("utils/requireActiveCompany"));
const Loader = lazy(() => import("components/Loader"));

// App
const NotFound = lazy(() => import("pages/NotFound"));

// Auth
const RegistrationLayout = lazy(() => import("layouts/Registration"));
const RegistrationUser = lazy(() => import("pages/authentification/registration/User"));
const RegistrationCompany = lazy(() => import("pages/authentification/registration/Company"));
const RegistrationPhoto = lazy(() => import("pages/authentification/registration/Photo"));
const RegistrationSettings = lazy(() => import("pages/authentification/registration/Settings"));
const RegistrationPending = lazy(() => import("pages/authentification/registration/Pending"));
const Login = lazy(() => import("pages/authentification/Login"));

// Platform
const PlatformLayout = lazy(() => import("layouts/Platform"));
const OrderContext = lazy(() => import("layouts/Order"));
const AccountContext = lazy(() => import("utils/context/account"));
const NewOrder = lazy(() => import("pages/platform/NewOrder"));
const Main = lazy(() => import("pages/platform/Main"));
const SuccessfulPayment = lazy(() => import("pages/platform/SuccessfulPayment"));
const AllBookings = lazy(() => import("pages/platform/AllBookings"));
const BookingDetails = lazy(() => import("pages/platform/BookingDetails"));
const Accounting = lazy(() => import("pages/platform/Accounting"));
const Articles = lazy(() => import("pages/platform/Articles"));
const Article = lazy(() => import("pages/platform/Article"));
const AccountProfile = lazy(() => import("pages/platform/Account/Profile"));
const AccountCompany = lazy(() => import("pages/platform/Account/Company"));
const AccountVouchers = lazy(() => import("pages/platform/Account/Vouchers"));
const OnlineChats = lazy(() => import("pages/platform/OnlineChats"));
const OnlineChat = lazy(() => import("pages/platform/OnlineChat"));
const Legal = lazy(() => import("pages/platform/Legal"));

function App() {
	return (
		<>
			<AppContextProvider>
				<Suspense fallback={<Loader />}>
					<Routes>
						<Route element={<RequireAuth />}>
							<Route element={<RegistrationLayout />}>
								<Route path="/" element={<Login />} noFooter={true} />
								<Route path="/sign-up" element={<RegistrationUser />} />

								<Route element={<RequireCompany />}>
									<Route path="/sign-up/company" element={<RegistrationCompany />} />
									<Route path="/sign-up/photo" element={<RegistrationPhoto />} />
									<Route path="/sign-up/settings" element={<RegistrationSettings />} />
									<Route path="/sign-up/pending" element={<RegistrationPending />} />
								</Route>
							</Route>

							<Route element={<RequireActiveCompany />}>
								<Route element={<PlatformLayout />}>
									<Route element={<OrderContext />}>
										<Route path="/dashboard" element={<Main title="Dashboard" />} />
										<Route path="/new-order" element={<NewOrder title="Book Transfer" />} />
										<Route
											path="/payment-success/:order_uuid"
											element={<SuccessfulPayment title="Order" />}
										/>
									</Route>
									<Route path="/all-bookings" element={<AllBookings title="All Bookings" />} />
									<Route
										path="/booking/:booking_uuid"
										element={<BookingDetails title="Trip details" />}
									/>
									<Route path="/accounting" element={<Accounting title="Accounting" />} />
									<Route element={<AccountContext />}>
										<Route path="/account/profile" element={<AccountProfile />} />
										<Route path="/account/company" element={<AccountCompany />} />
										<Route path="/account/vouchers" element={<AccountVouchers />} />
									</Route>
									<Route path="/online-chat" element={<OnlineChats title="Online Chat" />} />
									<Route path="/online-chat/:booking_uuid" element={<OnlineChat title="Chat" />} />
									<Route path="/articles" element={<Articles title="Articles" />} />
									<Route path="/article/:article_slug" element={<Article title="Article" />} />
									<Route path="/legal" element={<Legal title="Legal" />} />
								</Route>
							</Route>
						</Route>

						<Route path="*" element={<NotFound />} />
					</Routes>
				</Suspense>
			</AppContextProvider>
		</>
	);
}

export default App;
