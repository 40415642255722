import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "locales/en/translation.json";

const lang = localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "en";

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		lng: lang,
		debug: false,
		fallbackLng: ["en"],
		resources: {
			en: {
				translation: translationEN,
			},
		},
	});

export default i18n;
